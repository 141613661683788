import { isValidPhoneNumber } from "libphonenumber-js";
import { pushToDataLayer } from "./analytics.js";

export function addEventListeners() {
  let clientIdFetched = false;

  document.addEventListener("input", async (e) => {
    if (e.target.tagName === "INPUT") {
      e.target.setCustomValidity("");
    }
  });

  document.addEventListener("submit", async (e) => {
    if (e.target.dataset.type === "enroll_form") {
      e.preventDefault();

      const submitButton = e.target.querySelector('button[type="submit"]');
      if (submitButton) {
        submitButton.disabled = true;
        submitButton.innerHTML =
          `<span class="spinner-grow spinner-grow-sm"` +
          `style="display: inline-block; margin-bottom: revert;" ` +
          `role="status" aria-hidden="true"></span> Sending...`;
      }

      if (!clientIdFetched) {
        const timeout = 1000; // wait for max 1 second to fetch client_id
        await getClientId(timeout).catch((error) => {
          pushToDataLayer("client_id_fetch_failure", { error_message: error });
        });
        clientIdFetched = true;
      }

      const phone = e.target.querySelector('input[name="applicant[phone]"]');
      if (!phone) {
        pushToDataLayer("input_not_found", { input_name: "applicant[phone]" });
        return;
      }

      if (isValidPhoneNumber(phone.value, "US")) {
        const siteKey = e.target.getAttribute("data-site-key");
        const id = "applicant_recaptcha_response_token";
        await getRecaptchaToken(siteKey, id).catch((error) => {
          pushToDataLayer("recaptcha_fetch_failure", {
            error_message: error,
          });
        });
        pushToDataLayer("form_submitted");

        e.target.submit();
      } else {
        pushToDataLayer("form_validation_failure");
        phone.setCustomValidity("Invalid phone number");
        phone.reportValidity();
        if (submitButton) {
          submitButton.innerHTML = "SEND";
          submitButton.disabled = false;
        }
      }
    }
  });

  // Reset submit button state when the page is shown - fixes back button issue
  window.addEventListener("pageshow", () => {
    const buttons = document.querySelectorAll('button[type="submit"]');
    buttons.forEach((button) => {
      button.disabled = false;
      button.innerHTML = "SEND";
    });
  });
}

function getRecaptchaToken(siteKey, id) {
  return new Promise((resolve, reject) => {
    if (typeof window.grecaptcha === "undefined") {
      reject("reCAPTCHA is not loaded.");
    }

    window.grecaptcha.enterprise.ready(function () {
      window.grecaptcha.enterprise
        .execute(siteKey, { action: "enroll" })
        .then(function (token) {
          if (!token) {
            reject("Failed to fetch reCAPTCHA token.");
          }

          const element = document.getElementById(id);
          if (element) {
            element.value = token;
          }

          resolve(token);
        })
        .then(function (token) {
          resolve(token);
        });
    });
  });
}

function getClientId(timeout = 1000) {
  return new Promise((resolve, reject) => {
    const applicant_client_id = document.getElementById("applicant_client_id");

    if (applicant_client_id && !applicant_client_id.value) {
      if (typeof window.gtag !== "function") {
        window.gtag = function () {
          window.dataLayer = window.dataLayer || [];
          window.dataLayer.push(arguments);
        };
      }

      let isResolved = false;
      window.gtag("get", "G-YGE4Z80K66", "client_id", (client_id) => {
        if (client_id) {
          applicant_client_id.value = client_id;
          if (!isResolved) {
            isResolved = true;
            resolve();
          }
        } else {
          if (!isResolved) {
            isResolved = true;
            reject("Failed to fetch client_id.");
          }
        }
      });

      setTimeout(() => {
        if (!isResolved) {
          isResolved = true;
          reject("Timeout waiting for client_id.");
        }
      }, timeout);
    } else {
      resolve();
    }
  });
}
