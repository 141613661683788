import { fetchVisitorIP } from "./ipServices.js";

export async function prepareForm() {
  const ipinfo = await fetchVisitorIP();
  if (ipinfo) {
    // cta message
    const fallCtaElements = document.querySelectorAll(".geo-message");
    if (fallCtaElements.length > 0) {
      fallCtaElements.forEach((element) => {
        if (ipinfo.region) {
          element.innerHTML = `Now Enrolling in ${ipinfo.region}`;
        }
      });
    }

    // form elements
    const applicant_ip = document.getElementById("applicant_ip");
    const applicant_state = document.getElementById("applicant_state");
    const applicant_country = document.getElementById("applicant_country");
    if (applicant_ip && ipinfo.ip) applicant_ip.value = ipinfo.ip;
    if (applicant_state && ipinfo.region) applicant_state.value = ipinfo.region;
    if (applicant_country && ipinfo.country)
      applicant_country.value = ipinfo.country;
  }
}
