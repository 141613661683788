import { pushToDataLayer } from "./analytics.js";

const fetchIPInfo = async (url, errorHandler) => {
  try {
    const response = await fetch(url, {
      headers:
        url === "https://ipinfo.io"
          ? {
              Accept: "application/json",
              Authorization: "Bearer c2a90ebb2a5792",
            }
          : {},
    });

    if (!response.ok) throw new Error("Response not OK");

    return await response.json();
  } catch (error) {
    pushToDataLayer(errorHandler, { error_message: error });
    return null;
  }
};

export async function fetchVisitorIP() {
  const cacheKey = "ipinfo";
  const ttl = 86400000; // 24 hours
  const cachedItem = localStorage.getItem(cacheKey);
  if (cachedItem) {
    const { data, timestamp } = JSON.parse(cachedItem);
    if (timestamp + ttl > Date.now()) {
      return data;
    }
  }

  const data =
    (await fetchIPInfo("https://ipinfo.io", "client_id_fetch_failure")) ||
    (await fetchIPInfo("/ipinfo", "client_id_fetch_failure_local"));

  if (data) {
    localStorage.setItem(
      cacheKey,
      JSON.stringify({ data, timestamp: Date.now() }),
    );
  }

  return data;
}
