import { addEventListeners } from "./formEventListeners.js";
import { prepareForm } from "./formPreparation.js";
import { pushToDataLayer } from "./analytics.js";

function setup() {
  try {
    addEventListeners();
    prepareForm();
  } catch (error) {
    console.error("Setup error:", error.message);
    pushToDataLayer("setup_error", { error_message: error.message });
  }
}

if (document.readyState === "loading") {
  document.addEventListener("DOMContentLoaded", setup);
} else {
  setup();
}
